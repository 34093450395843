import request from './request'

// 获取新增电子秤列表
export const GETproductCategory = (params) => {
  return request({
    url:'admin/v1/sellerParameters',
    method: 'GET',
    params
  })
}
// 台账新增
export const POSTsellerParameters = data => {
  return request({
    url: 'admin/v1/sellerParameters',
    method: 'POST',
    data
  })
}
// 获取市场下的商户列表

// 获取新增电子秤列表
export const getProductCategory = params => {
  return request({
    url:'admin/v1/getSellerByMarketId',
    method: 'GET',
    params
  })
}
// 根据商户查询商品分类
export const superiorCategoryList = params => {
  return request({
    url:'/admin/v1/superiorCategoryList',
    method: 'GET',
    params
  })
}
