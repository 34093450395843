<template>
  <div>
    <div class="">
      <el-row>
        <el-col :span="3" style="margin-right: 15px">
          <el-select
            v-model="pragms.market_id"
            placeholder="请选择市场"
            clearable
          >
            <el-option
              v-for="item in Form.marketid"
              :key="item.id"
              :label="item.comp_name"
              :value="item.id"
              @click.native="getByMarketId"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3" style="margin-right: 15px">
          <el-select
            v-model="pragms.seller_id"
            placeholder="请选择商户"
            clearable
          >
            <el-option
              v-for="item in Form.sellerid"
              :key="item.id"
              :label="item.name"
              :value="item.id"
              @click.native="getCategoryList"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3" style="margin-right: 15px">
          <el-select
            v-model="pragms.category_id"
            placeholder="请选择商品类目"
            clearable
          >
            <el-option
              clearable
              v-for="item in Form.shopid"
              :key="item.id"
              :label="item.name"
              :value="item.id"
              @click.native="getByMarketId"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          <el-date-picker
            clearable
            v-model="payTime"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="3" style="margin-right: 15px">
          <el-input
            clearable
            v-model="pragms.batch_id"
            placeholder="请输入台账批次号"
          ></el-input>
        </el-col>
        <el-col :span="2" style="display: flex">
          <el-button type="primary" @click="GET_productCategory"
            >查询</el-button
          >
          <el-button
            style="margin-left:60px;width:100px"
            type="primary"
            @click="centerDialogVisible = true"
            >新增</el-button
          >
        </el-col>
      </el-row>
    </div>
    <el-table
      :data="tableData.data"
      :cell-style="{ textAlign: 'center' }"
      :header-cell-style="{ textAlign: 'center' }"
      border
      style="width: 100%;margin-top:20px"
    >
      <el-table-column prop="id" label="台账ID"> </el-table-column>
      <el-table-column prop="batch_id" label="批次号"> </el-table-column>
      <el-table-column prop="market_name" label="市场名称"> </el-table-column>
      <el-table-column prop="seller_name" label="商户名称"> </el-table-column>
      <el-table-column prop="shop_name" label="店铺名称"> </el-table-column>
      <el-table-column prop="address" label="商户负责人"> </el-table-column>
      <el-table-column prop="purchase_time" label="进货时间"> </el-table-column>
      <el-table-column prop="address" label="台账照片">
        <template slot-scope="scope">
          <img
            :src="scope.row.tra_certificate"
            style="width:120px;height:50px"
            alt=""
            @click="$refs.img.previewImage(scope.row.tra_certificate)"
          />
        </template>
      </el-table-column>
      <el-table-column prop="supplier_name" label="供应商"> </el-table-column>
      <el-table-column prop="supplier_id" label="供应商ID"> </el-table-column>
      <el-table-column prop="address" label="上传时间"> </el-table-column>
    </el-table>
    <div class="footers">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        current-page.sync="pragms.page"
        :page-sizes="[10, 20, 30]"
        :page-size="pragms.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.total"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="新增台账"
      :visible.sync="centerDialogVisible"
      width="40%"
      center
      @close="titleclick"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <div style=" display:flex;justify-content: space-between;">
          <div>
            <el-form-item label="市场" prop="market_id">
              <el-select v-model="ruleForm.market_id" placeholder="请选择市场">
                <el-option
                  v-for="item in Form.marketid"
                  :key="item.id"
                  :label="item.comp_name"
                  :value="item.id"
                  @click.native="ByMarketId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="商户"
              prop="seller_id"
              v-if="ruleForm.market_id"
            >
              <el-select v-model="ruleForm.seller_id" placeholder="请选择商户">
                <el-option
                  v-for="item in Form.sellerid"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  @click.native="ShopBySellerId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="店铺" prop="shop_id" v-if="ruleForm.seller_id">
              <el-select v-model="ruleForm.shop_id" placeholder="请选择店铺">
                <el-option
                  v-for="item in Form.shopid"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  @click.native="clickShopid"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="进货时间" prop="region">
              <el-date-picker
                v-model="ruleForm.purchase_time"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="商品" prop="region" v-if="ruleForm.shop_id">
              <el-select
                multiple
                v-model="ruleForm.product"
                placeholder="请选择商品"
                value-key="id"
              >
                <el-option
                  v-for="item in Form.productsellerid.data"
                  :key="item.id"
                  :label="item.veg_name"
                  :value="item"
                  @click.native="getregin"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div>
            <el-form-item
              label="供应商"
              prop="region"
              v-if="ruleForm.seller_id"
            >
              <el-select
                v-model="ruleForm.supplier_id"
                placeholder="请选择供应商"
              >
                <el-option
                  v-for="item in supplierlist"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="台账批次号" prop="name">
              <el-input
                style="width:217px"
                placeholder="请输入台账批次号"
                v-model="ruleForm.batch_id"
              ></el-input>
            </el-form-item>
            <el-form-item label="台账图片" prop="region">
              <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :http-request="uploadSectionFile1"
                :before-upload="beforeAvatarUpload"
              >
                <img
                  v-if="ruleForm.tra_certificate"
                  :src="ruleForm.tra_certificate"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <el-table
        ref="table"
        :data="tableDatas"
        v-if="!tableDatas.length == 0"
        border
        style="width: 100%"
        @cell-dblclick="tableDbEdit"
      >
        <el-table-column prop="product" label="商品名称">
          <template slot-scope="scope">
            <!-- 条件判断如果满足则显示表单，否则默认展示文字 -->
            <el-input
              type="textarea"
              size="small"
              v-model="scope.row.product"
              v-if="showInput == `product${scope.row.product_id}`"
              @blur="
                blurInput(scope.row.product_id, 'product', scope.row.product)
              "
              v-focusTextarea
            >
            </el-input>
            <p v-else>{{ scope.row.product }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="veg_num" label="商品数量">
          <template slot-scope="scope">
            <el-input
              size="small"
              v-model="scope.row.veg_num"
              v-if="showInput == `veg_num${scope.row.product_id}`"
              @blur="
                blurInput(scope.row.product_id, 'veg_num', scope.row.veg_num)
              "
              v-focusTextarea
            >
            </el-input>
            <p v-else>{{ scope.row.veg_num }}</p>
          </template>
        </el-table-column>
        <el-table-column label="商品成本" width="150" prop="cost">
          <template slot-scope="scope">
            <el-input
              size="small"
              v-model="scope.row.cost"
              v-if="showInput == `cost${scope.row.product_id}`"
              @blur="blurInput(scope.row.product_id, 'cost', scope.row.cost)"
              v-focus
            ></el-input>
            <p v-else>{{ scope.row.cost }}</p>
          </template>
        </el-table-column>
      </el-table>
      <el-button type="primary" style="margin-top:100px" @click="submitForm()"
        >添加</el-button
      >
    </el-dialog>
    <DialogImg v-show="0" ref="img" />
  </div>
</template>

<script>
import {
  GETproductCategory,
  getProductCategory,
  superiorCategoryList
} from '../../../api/backstageList'
import {
  POSTusellerParametersits,
  GETsupplier,
  getSellerByMarketId,
  GETShopBySellerId,
  getProductBySellerId,
  productCategory
} from '../../../api/productList'
import { getmarket } from '../../../api/layout'

import { upload } from '../../../utile/upload'
export default {
  name: 'backstage',
  data () {
    return {
      value1: '',
      value: '',
      tableDatas: [],
      imageUrl: '',
      state: '',
      restaurants: [],
      tableData: '',
      centerDialogVisible: false,
      Form: {
        marketid: '', // 市场数据
        sellerid: '', // 商户
        shopid: '', // 店铺
        productsellerid: '' // 商品
      },
      ruleForm: {
        market_id: '', // 市场ID
        seller_id: '', // 商户ID
        shop_id: '', // 店铺
        product: [], // 商品
        batch_id: '', // 台账批次号
        supplier_id: '', // 供应商
        purchase_time: '', // 进货时间
        tra_certificate: '' // 台账图片
        // product_id: '', // 商品ID
        // veg_num: '', // 商品数量
        // cost: '' // 商品成本
      },
      rules: {},
      showInput: '',
      oldData: {},
      options: [],
      // 供应商
      supplierlist: [],
      pragms: {
        market_id: '',
        seller_id: '',
        category_id: '',
        start_date: '',
        end_date: '',
        limit: 10,
        page: 1
      },
      payTime: ''
    }
  },
  directives: {
    // 通过自定义指令实现的表单自动获得光标的操作
    focus: {
      inserted: function (el) {
        if (el.tagName.toLocaleLowerCase() === 'input') {
          el.focus()
        } else {
          if (el.getElementsByTagName('input')) {
            el.getElementsByTagName('input')[0].focus()
          }
        }
        el.focus()
      }
    },
    focusTextarea: {
      inserted: function (el) {
        if (el.tagName.toLocaleLowerCase() === 'textarea') {
          el.focus()
        } else {
          if (el.getElementsByTagName('textarea')) {
            el.getElementsByTagName('textarea')[0].focus()
          }
        }
        el.focus()
      }
    }
  },
  methods: {
    // 清空新增数据
    titleclick () {
      // console.log('ddddd')
      this.ruleForm.product = []
      this.ruleForm.batch_id = ''
      this.ruleForm.supplier_id = ''
      this.ruleForm.purchase_time = ''
      this.ruleForm.market_id = ''
      this.ruleForm.tra_certificate = ''
      this.ruleForm.market_id = ''
      this.ruleForm.seller_id = ''
      this.ruleForm.shop_id = ''
      this.tableDatas = []
    },
    tableDbEdit (row, column, event) {
      console.log(row)
      console.log(column)
      this.showInput = column.property + row.product_id
      console.log(this.showInput)
      this.oldData[column.property] = row[column.property]
    },
    shuru (val) {
      console.log(val)
      val.veg_num = this.ruleForm.veg_num
      val.cost = this.ruleForm.cost
      // this.tableDatas.push(val)
      // console.log(this.tableDatas)
      // const tmpObj = this.curHomePageExchangeGoodsList[val]
      // this.$set(this.curHomePageExchangeGoodsList, val, tmpObj)
    },

    // 动态获取商品列表数据
    getregin (item) {
      const product = this.ruleForm.product
      // console.log(product)
      this.tableDatas = []
      for (const key in product) {
        this.tableDatas[key] = {}
        this.tableDatas[key].product = product[key].veg_name
        this.tableDatas[key].product_id = product[key].id
        this.tableDatas[key].veg_num = ''
        this.tableDatas[key].cost = ''
        // this.tableDatas.push(product[key])
      }
      console.log(this.tableDatas)
    },
    // 表格数据
    GET_productCategory () {
      if (this.payTime && this.payTime.length) {
        this.pragms.start_date = this.payTime[0]
        this.pragms.end_date = this.payTime[1]
      } else {
        this.pragms.start_date = ''
        this.pragms.end_date = ''
      }
      GETproductCategory(this.pragms).then(res => {
        this.tableData = res.data
      })
    },
    // 每一页的条数 10/条
    handleSizeChange (val) {
      this.pragms.limit = val
      this.GET_productCategory()
    },
    // 当前的页码
    handleCurrentChange (val) {
      this.pragms.page = val
      this.GET_productCategory()
    },
    // 市场
    get_market () {
      getmarket().then(res => {
        this.Form.marketid = res.data
      })
    },
    // 商户
    ByMarketId () {
      this.ruleForm.seller_id = ''
      this.ruleForm.shop_id = ''
      getSellerByMarketId(this.ruleForm.market_id).then(res => {
        this.Form.sellerid = res.data
      })
    },
    // 根据市场查询商户
    getByMarketId () {
      // {market_id:}
      console.log(this.pragms.market_id)
      getProductCategory({
        market_id: this.pragms.market_id,
        category_id: this.pragms.category_id
      }).then(res => {
        this.Form.sellerid = res.data
      })
    },
    getCategoryList () {
      superiorCategoryList({
        seller_id: this.pragms.seller_id
      }).then(res => {
        this.Form.shopid = res.data
      })
    },

    // 店铺
    ShopBySellerId () {
      this.ruleForm.shop_id = ''
      GETShopBySellerId(this.ruleForm.seller_id).then(res => {
        this.Form.shopid = res.data
      })
      this.suppliers()
    },
    // 商品
    clickShopid () {
      this.ruleForm.product_seller_id = ''
      getProductBySellerId(
        this.ruleForm.market_id,
        this.ruleForm.seller_id
      ).then(res => {
        this.Form.productsellerid = res.data
      })
    },
    // 上传图片的校验
    beforeAvatarUpload (file) {
      const isJPG1 = file.type === 'image/png'
      const isJPG2 = file.type === 'image/jpeg'
      const isJPG3 = file.type === 'image/bmp'
      const isJPG4 = file.type === 'image/gif'
      const img = [isJPG1, isJPG2, isJPG3, isJPG4]
      const some = img.some(item => {
        return item
      })
      if (!some) {
        this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
      }
      return some
    },
    // 获取真实的图片路径 营业执照
    uploadSectionFile1 (param) {
      upload(param)
        .then(res => {
          this.ruleForm.tra_certificate = res
        })
        .catch(err => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    // 供应商
    suppliers () {
      GETsupplier(this.ruleForm.seller_id).then(res => {
        if (res.code === 200) {
          // console.log(res.data)
          this.supplierlist = res.data
          // this.GET_productCategory()
          // this.centerDialogVisible = false
        }
      })
    },

    // 新增
    submitForm () {
      if (!Number(this.ruleForm.market_id)) {
        return this.$message.error('请先选择市场')
      }
      if (!this.ruleForm.supplier_id) {
        return this.$message.error('请先选择供应商')
      }
      if (!this.ruleForm.purchase_time) {
        return this.$message.error('请先选择进货时间')
      }
      if (!this.ruleForm.batch_id) {
        return this.$message.error('请先选择台账批次号')
      }
      if (!this.ruleForm.tra_certificate) {
        return this.$message.error('请上传营业执照')
      }

      if (this.ruleForm.product.length === 0) {
        return this.$message.error('请选择填写商品信息')
      }

      this.ruleForm.product = this.tableDatas
      POSTusellerParametersits(this.ruleForm).then(res => {
        if (res.code === 200) {
          this.GET_productCategory()
          this.centerDialogVisible = false
          this.titleclick()
          this.tableDatas = []
        } else {
        }
      })
    }
  },
  mounted () {
    this.GET_productCategory()
    this.get_market()
    this.getCategoryList()
  }
}
</script>

<style lang="less" scoped>
.title1 {
  display: flex;
}
.width {
  width: 500px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title2 {
  width: 80%;
  display: flex;
  margin-top: 20px;
}
.footers {
  text-align: right;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 130px;
  height: 130px;
  line-height: 130px;
  text-align: center;
}
.avatar {
  width: 130px;
  height: 130px;
  display: block;
}
</style>
